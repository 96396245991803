section.abour-banner-wrap {
    background-attachment: inherit !important;
    margin-top: 0;
    padding: 200px 0 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #007fa3;
    position: relative;
}

.abour-banner-wrap .about-banner-content h1 {
    font-size: 50px;
    text-transform: capitalize;
    font-weight: 600;
    color: #fff;
    line-height: 60px;
    width: 75%;
    margin: 0 auto 20px;
}

.abour-banner-wrap .about-banner-content {
    text-align: center;
}

.term-sec-wrap .terms-content h2 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 42px;
    color: #242f40;
    font-family: Poppins, sans-serif !important;
    margin: 0px 0 20px 0;
    line-height: 50px;
}


.term-sec-wrap .terms-content p {
    color: #333;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 20px 0;
}

.term-sec-wrap {
    padding: 50px 0;
}