.services {
    padding-top: 8rem;
    padding-bottom: 5rem;
    font-family: "Raleway", sans-serif;
}

.services .container {
    text-align: center;
}

.services .services-heading {
    font-weight: 800;
    font-size: 3.5rem;
    color: #494949;
    line-height: 1.3;
}

.services .services-text {
    font-weight: 500;
    font-size: 20px;
    color: #343434;
    padding-left: 10rem;
    padding-right: 10rem;
}

.services .our-service {
    position: relative;
    margin: 20px 0px;
}

.services .our-service h2 {
    position: absolute;
    bottom: 45%;
    z-index: 1;
    text-align: center;
    width: 100%;
    margin: 0px;
    padding: 10px 10px;
    background: #ffffffbd;
    font-size: 20px;
    transition: 0.7s ease;
    font-weight: 600;
}

.services .our-service img {
    width: 100%;
    filter: blur(6px);
    transition: 0.7s ease;
}

.services .our-service:hover h2 {
    bottom: 4%;
}

.services .our-service:hover img {
    filter: blur(0px);
}

.services .services-btn {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.services .modal-btn {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}

.services .phn-btn {
    background: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}


@media (max-width: 991.98px) {
    .services .services-heading {
        font-size: 3rem;
        line-height: 1.1;
    }

    .services .services-text {
        padding-left: 0;
        padding-right: 0;

    }
}

@media (max-width: 575.98px) {
    .services .services-heading {
        font-size: 2rem;
    }

    .services .services-text {
        font-size: 1rem;
    }
}