.footer {
    font-family: "Raleway", sans-serif;
    background-color: #007fa3;
    color: white;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border: 1px solid black;
}

.footer .col-md-4 {
    display: flex;
    gap: 1rem;
}

.footer .copyright {
    justify-content: center;
    padding-top: 0.7rem;
    font-size: 12px;
}

.footer .terms-privacy {
    justify-content: center;
    padding-top: 0.7rem;
    font-size: 13px;
}

.footer a {
    text-decoration: none;
    color: white;
}