.catchup {
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: "Raleway", sans-serif;
    background-color: black;
    color: white;
}

.catchup .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.catchup .catchup-heading {
        font-weight: 800;
        font-size: 52px;
        line-height: 61px;
}

.catchup .catchup-text {
        font-weight: 500;
        font-size: 20px;
}

.catchup .catchup-btn {
    display: flex;
    gap: 1rem;
}

.catchup .modal-btn {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}

.catchup .phn-btn {
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: black;
    text-transform: capitalize;
    padding: 1rem 2rem;
}


@media (max-width: 767.98px) {
    .catchup .catchup-heading {
        font-size: 2.5rem;
    }
    .catchup .catchup-text {
        font-size: 1rem;
        text-align: center;
    }
}

@media (max-width: 575.98px) {
    .catchup .catchup-heading {
        font-size: 2rem;
    }
}