.cta-one {
    background-color: #007fa3;
    color: white;
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-family: "Raleway", sans-serif;
    position: relative;
}

.cta-one .col-md-4 {
    position: absolute;
    top: -3rem;
    right: 0rem;
}

.cta-one .cta-one-heading {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1;
}

.cta-one .cta-one-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
}

.cta-one .cta-one-btn {
    padding-top: 1rem;
    display: flex;
    gap: 1rem;
}

.cta-one .cta-one-btn a {
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
}


.cta-one .modal-btn {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}

.cta-one .phn-btn {
    background: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}

@media (max-width: 1200px) {
    .cta-one .col-md-4 {
        right: 7rem;
    }
}

@media (max-width: 991.98px) {
    .cta-one .col-md-4 {
        display: none;
    }
}


@media (max-width: 767.98px) {
    .cta-one .cta-one-heading {
        font-size: 3rem;
        line-height: 1;
    }

    .cta-one .cta-one-text {
        font-size: 1rem;
        font-weight: 500;
    }
}


@media (max-width: 575.98px) {
    .cta-one .cta-one-heading {
        font-size: 2rem;
    }

    .cta-one .cta-one-text {
        font-size: 1rem;
    }
}