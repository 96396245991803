.footer-form {
    font-family: "Raleway", sans-serif;
    font-size: 0.8rem;
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.footer-form .message {
    padding-bottom: 5rem;
}

.footer-form .footer-form-heading {
    font-size: 3.5rem;
    font-weight: 700;
}

.footer-form .footer-form-text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 28px;
    color: #666;
}

.footer-form input[type=submit] {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%) !important;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%) !important;
    border-radius: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 23px !important;
    color: #fff !important;
    text-transform: uppercase;
    width: 200px !important;
}

.footer-form .submit {
    border: none;
    color: #fff;
    padding: 13px 40px;
    font-size: 16px;
    border-radius: 3px;
    text-transform: capitalize;
    font-weight: 500;
    transition: 0.4s;
    outline: 0;
} 

@media (max-width: 767.98px) {
    .footer-form .footer-form-heading {
        font-size: 3rem;
    }
}

@media (max-width: 575.98px) {
    .footer-form .footer-form-heading {
        font-size: 2rem;
    }
}