.process {
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-family: "Raleway", sans-serif;
    background-image: url(../../assets/images/proces.png);
    background-repeat: no-repeat;
}

.process .process-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    color: #494949;
    line-height: 1;
    padding-left: 5rem;
    padding-right: 5rem;
}

.process .process-text {
    font-weight: 500;
    font-size: 20px;
    color: #343434;
    line-height: 1;
}

.process .process-steps {
    padding-top: 3rem;
    justify-content: center;
    text-align: center;
    align-items: baseline;
    gap: 0rem;
}

.process img {
    max-width: 50%;
    height: 100px;
}

.process .tedy {
    max-width: 80%;
    height: auto;
}


.process .offers-box-heading {
    font-weight: 700;
    font-size: 20px;
    color: #343434;
}

.process .offers-box-text {
    font-weight: 500;
    font-size: 1rem;
    color: #343434;
}

@media (max-width: 767.98px) {
    .process .process-steps {
        gap: 2rem;
    }
    .process .process-heading {
        font-size: 3rem;
        padding-left: 0;
        padding-right: 0;
    }

    .process .tedy {
        max-width: 50%;
    }
}

@media (max-width: 575.98px) {
    .process .process-heading {
        font-size: 2rem;
    }

    .process .process-text{
        font-size: 1rem;
    }
}