.about {
    padding-top: 5rem;
    padding-bottom: 10rem;
    font-family: "Raleway", sans-serif;
}

.about .container .row {
    justify-content: center;
}

.about .about-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about .about-heading {
    font-weight: 800;
    font-size: 3.3rem;
    color: #494949;
    line-height: 1;
}

.about .about-text {
    font-size: 1.3rem;
    line-height: 1.4;
}

.about .btn-row {
    padding-top: 3rem;
}

.about .col-lg-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.about .col-lg-3 img {
    max-width: 50%;
}

.about .about-btn {
    background-color: #007fa3;
    color: white;
    border-radius: 20px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    padding: 1rem 1rem;
    width: 100%;
}

@media (max-width: 991.98px) {
    .about .btn-row {
        gap: 2rem;
    }
}

@media (max-width: 767.98px) {
    .about .about-heading {
        font-size: 2.7rem;
    }

    .about .about-text {
        font-size: 1.2rem;
    }

    .about .col-lg-3 img {
        max-width: 30%;
    }

    .about .about-btn {
        width: auto;
    }
}

@media (max-width: 575.98px) {
    .about .about-heading {
        font-size: 2rem;
    }

    .about .about-text {
        font-size: 1rem;
    }
}