.thankyou_sec {
    padding: 100px 0;
    /* height: 85vh; */
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.thankyou_sec + footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
}
.main-thankyou {
    text-align: center;
}
.site-header__title {
    font-size: 6.5rem;
    font-weight: 700;
    line-height: 1.1;
}
.main-content__checkmark {
    font-size: 9.75rem !important;
    line-height: 1 !important;
    color: #3366cc;
}
.main-content__checkmark {
    font-size: 9.75rem;
    line-height: 1;
    color: #ea2265;
}

.main-content__body {
    margin: 20px 0 0;
    font-size: 1.25rem;
    line-height: 1.4;
}