.testimonials {
    font-family: "Raleway", sans-serif;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
}


.testimonials .testimonials-heading {
    font-weight: 800;
    font-size: 52px;
    line-height: 1;
}

.testimonials .testimonials-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
}

.testimonials .testimonials-row {
    justify-content: center;
    gap: 1rem;
}

.testimonials .testimonials-row .col-lg-5 {
    background-image: url(../../assets/images/quoteimg.webp);
    background-repeat: no-repeat;
    padding: 40px 35px 20px;
    -moz-box-shadow: 0 0 22px -5px rgba(0, 0, 0, 0.11);
    -o-box-shadow: 0 0 22px -5px rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 22px -5px rgba(0, 0, 0, 0.11);
    
}

.testimonials .testimonials-row .cir {
    text-align: center;
    width: 90px;
    padding-top: 24px;
    position: relative;
    height: 90px;
    margin: 0 auto 35px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 25px #00000017;
}

.testimonials .testimonials-row .cir h3 {
    font-size: 36px;
    color: #0784a7;
}

.testimonials .testimonials-row .wrap h6 {
    color: #2c2c2c;
    font-size: 16px;
    padding-bottom: 30px;
    font-weight: 300;
    line-height: 1.6;
    text-transform: unset;
    height: 145px;
    margin: 0 1rem 1rem;
    overflow-y: scroll;
}

.testimonials .testimonials-row .wrap h5 {
    color: #0784a7;
    font-size: 18px;
}

.testimonials .testimonials-row .wrap h5 span {
    font-size: 14px;
    color: #000;
    opacity: 0.6;
    display: block;
    margin-top: 5px;
}

@media (max-width: 767.98px) {
    .testimonials .testimonials-heading {
        font-size: 3rem;
    }

    .testimonials .testimonials-text {
        line-height: 1.3;
    }
}

@media (max-width: 575.98px) {
    .testimonials .testimonials-heading {
        font-size: 2rem;
    }

    .testimonials .testimonials-text {
        font-size: 1rem;
    }
}