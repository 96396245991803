.offers {
    padding-top: 8rem;
    padding-bottom: 5rem;
    font-family: "Raleway", sans-serif;
    background-color: #007fa3;
    color: white;
}

.offers .container {
    text-align: center;
}

.offers .offers-heading {
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 1.3;
}

.offers .offers-text {
    font-weight: 500;
    font-size: 20px;
    padding-left: 10rem;
    padding-right: 10rem;
}

.offers .offers-box {
    background-color: white;
    color: black;
    padding: 1.2rem;
    border-radius: 20px;
}

.offers img {
    max-width: 50%;
    height: 100px;
    padding-bottom: 0.5rem;
}

.offers .row {
    justify-content: center;
    gap: 2rem;
}

.offers .offers-box .offers-box-heading {
        font-weight: 700;
        font-size: 20px;
        color: #343434;
        text-align: center;
}

.offers .offers-box .offers-box-text {
        font-weight: 500;
        font-size: 1rem;
        color: #343434;
        text-align: center;
}


@media (max-width: 991.98px) {
    .offers .offers-heading {
        font-size: 3rem;
        line-height: 1.1;
    }

    .offers .offers-text {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 575.98px) {
    .offers .offers-heading {
        font-size: 2rem;
    }

    .offers .offers-text {
        font-size: 1rem;
    }
}

