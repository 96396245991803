.banner {
    font-family: "Raleway", sans-serif;
    padding-top: 20rem;
    padding-bottom: 5rem;
    background-image: url(../../assets/images/banner-background.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    position: relative;
}


.banner .row {
    justify-content: space-around;
}

.banner .col-lg-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner .col-md-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner .banner-heading {
    font-size: 4rem;
    color: #007fa3;
    font-weight: 800;
    line-height: 1.1;
}

.banner .banner-text {
    font-size: 1.3rem;
}

.banner .banner-btn {
    display: flex;
    gap: 2rem;
    width: 100%;
}

.banner .banner-form {
    background: rgba(255, 255, 255, .8);
    box-shadow: 0 4px 20px rgba(0, 0, 0, .25);
    padding: 2rem;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.banner .banner-form .banner-form-text {
    color: #007fa3;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.banner .banner-form .banner-form-text span {
    color: black;
}

.banner .banner-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.banner .banner-form input {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 30px;
    padding-left: 2rem;
}

.banner .banner-form .submit-btn {
    background-color: #007fa3;
    color: white;
    margin: auto;
    padding: 1rem 3rem;
    border-radius: 30px;
}

.banner-form-image {
    position: absolute;
    right: 0rem;
}

.banner .modal-btn {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}

.banner .phn-btn {
    background: black;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    padding: 1rem 2rem;
}

@media (max-width: 1500px) {
    .banner-form-image {
        opacity: 0.7;
        
    }
}

@media (max-width: 1200px) {
    .banner-form-image {
        display: none;
        
    }
}


@media (max-width: 991.98px) {
    .banner .col-lg-6 {
        padding-bottom: 3rem;
    }

    .banner {
        height: auto;   
    }
}

@media (max-width: 767.98px) {
    .banner {
        padding-top: 15rem;
    }

    .banner .banner-heading {
        font-size: 3rem;
    }

    .banner .banner-text {
        font-size: 1.2rem;
    }
}
@media (max-width: 575.98px) {
    .banner .banner-heading {
        font-size: 2rem;
    }

    .banner .banner-text {
        font-size: 1rem;
    }
}