.modal-body {
    padding: 7px 0px 0 40px !important;
    position: relative;
    left: -34px;
    top: -10px;
}
.popup_content {
    padding: 25px 0 0 30px;
}

.popup_content {
    position: relative;
    left: 9px;
}

.popup_content h2 {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    position: relative;
    left: -10px;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    font-family: 'Poppins' ! IMPORTANT;
}

.popup_content h2 span {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    font-family: 'Poppins' !important;
    text-decoration: underline;
}

.popup_content form {
    margin: 30px 0 3px -20px;
    /* width: 90%; */
    /* padding: 20px 50px 0 50px; */
    text-align: center;
}

.popup_content form input, .popup_content form textarea {
    width: 100%;
    height: 45px;
    padding: 0 0 0 20px;
    margin: 0 0 15px 0;
    border-radius: 10px;
    border: 1px solid #c5c5c5;
    outline: none;
}

.modal-content {
    margin: auto;
    position: relative;
    border-radius: 20px !important;
    z-index: 99999;
    height: 590px;
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%) !important;
}

.modal input[type=submit] {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%) !important;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%) !important;
    border-radius: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 23px !important;
    color: #fff !important;
    text-transform: uppercase;
    width: 200px !important;
}

.modal .btn-close {
    position: absolute;
    top: 2rem;
    left: 30rem;
    z-index: 111111111111;
}

@media (max-width: 475.98px) {
    .popup_content h2 {
        font-size: 1.5rem;
    }

    .popup_content h2 span {
        font-size: 1.2rem;
    }

    .popup_content {
        top: 2rem;
    }
}