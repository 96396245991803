.last-cta {
    background-color: #007fa3;
    /* background-image: url(../../assets/images/last-cta.jpg); */
    background-repeat: no-repeat;
    color: white;
    font-family: "Raleway", sans-serif;
    padding-top: 7rem;
    padding-bottom: 3rem;
    text-align: center;
    position: relative;
}

.last-cta .container {
    position: relative;
    z-index: 1;
}

.last-cta:before {
    content: '';
    display: block;
    position: absolute;
    background-image: url(../../assets/images/order-left.png);
    background-repeat: no-repeat;
    width: 370px;
    top: -3rem;
    height: 450px;
    left: 40px;
}

.last-cta:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url(../../assets/images/order-right.png);
    width: 198px;
    height: 320px;
    right: 0;
    top: 4.9rem;
}

/* @media only screen and (max-width: 1400px) {
    .last-cta:before {
        left: 0;
        background-size: 90%;
    }
} */



.last-cta .col-md-12 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.last-cta .last-cta-heading {
    font-weight: 800;
    font-size: 52px;
    line-height: 1;
}

.last-cta .last-cta-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
}

.last-cta a {
    margin: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .25);
}

.last-cta .modal-btn {
    background: linear-gradient(135deg, #007fa3 0, #079cc5 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    border-radius: 20px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
    width: 200px;
    height: 50px; 
}

@media (max-width: 1200px) {
    .last-cta:before, .last-cta:after {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .last-cta .last-cta-heading {
        font-size: 3rem;
    }
}

@media (max-width: 575.98px) {
    .last-cta .last-cta-heading {
        font-size: 2rem;
    }

    .last-cta .last-cta-text {
        font-size: 1rem;
    }
}