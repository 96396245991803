header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-family: "Raleway", sans-serif;
    background-color: white;
    box-shadow: 0 5px 20px #00000012;
    position: fixed;
    width: 100%;
    z-index: 11;
}

header img {
    max-width: 50% !important;
}

.button-col {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
}

.phn-btn {
    background-color: white;
    padding: 0.7rem;
    border: 1px solid #007fa3;
    text-decoration: none;
    color: #0784a7;
}

.modal-btn {
    background-color: #007fa3;
    padding: 0.7rem;
    text-decoration: none;
    color: white;
}

.chat-btn {
    background-color: white;
    padding: 0.7rem;
    border: 1px solid #007fa3;
    text-decoration: none;
    color: #0784a7;
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
    header img {
        max-width: 100% !important;
    }
}

@media (max-width: 767.98px) {
    .logo-col {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;
    }

    .button-col {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 576.98px) {
    .button-col {
        font-size: 14px;
    }
}